import axios from "axios";

export default class API{
    constructor() {

    }
    _request(params){
        if (!params.method) params.method = 'get'
        return new Promise((resolve,reject)=>{
            axios(params)
                .then(res=>resolve(res.data))
                .catch(err=>{
                    console.error(err)
                    reject(err)
                })
        })
    }
    getMain(){
        let url = '/api/landing/main/'
        return this._request({ url: url})
    }
    getServicePage(){
        let url = '/api/landing/service_page/'
        return this._request({ url: url})
    }
    getPartsPage(){
        let url = '/api/landing/parts_page/'
        return this._request({ url: url})
    }
    getContactsPage(){
        let url = '/api/landing/contacts_page/'
        return this._request({ url: url})
    }
    getVacancyPage(){
        let url = '/api/landing/vacancy_page/'
        return this._request({ url: url})
    }
    getStockholdersPage(){
        let url = '/api/landing/stockholders_page/'
        return this._request({ url: url})
    }
    getAboutPage(){
        let url = '/api/landing/about_page/'
        return this._request({ url: url})
    }
    getPartsPageSection(sectionId='0'){
        let url = `/api/landing/parts_page/section/${sectionId}/`
        return this._request({ url: url})
    }
    getStockholdersPageSection(sectionId='0'){
        let url = `/api/landing/stockholders_page/section/${sectionId}/`
        return this._request({ url: url})
    }
    getAboutPageSection(sectionId='0'){
        let url = `/api/landing/about_page/section/${sectionId}/`
        return this._request({ url: url})
    }
}
