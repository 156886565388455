import { createRouter, createWebHistory } from 'vue-router'
import SiteBase from "../views/SiteBase"
import PartsPage from "@/views/PartsPage"
import ContactsPage from "@/views/ContactsPage"
import AboutPage from "@/views/AboutPage"
import StockholdersPage from "@/views/StockholdersPage"
import VacancyPage from "@/views/VacancyPage";
import HomePage from "@/views/HomePage";
import ServicesPage from "@/views/ServicesPage";

const routes = [
    {
        path: '/',
        name: 'SiteBase',
        component: SiteBase,
        redirect: {name: 'home'},
        children: [
          {path: 'home', name: 'home', component: HomePage},
          {path: 'services', name: 'services', component: ServicesPage},
          {path: 'parts', name: 'PartsPage', component: PartsPage},
          {path: 'contacts', name: 'ContactsPage', component: ContactsPage},
          {path: 'about', name: 'AboutPage', component: AboutPage},
          {path: 'stockholders', name: 'StockholdersPage', component: StockholdersPage},
          {path: 'vacancy', name: 'VacancyPage', component: VacancyPage},
        ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
     scrollBehavior () {
    // document.getElementById('scroll-content').scrollTo(0,0)
   return {
     el: '.container',
     top: 0
   }
 },
})

export default router
