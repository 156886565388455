<template>
  <transition name="fade">
    <div class="popup-modal" v-if="isVisible" @click.self="close">
      <div class="modal__backdrop" @click="close()"/>

      <div class="modal__dialog">
        <div class="modal__header">
          <slot name="headerModal"/>
          <button type="button" class="modal__close" @click="close()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
              <path
                fill="currentColor"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
              ></path>
            </svg>
          </button>
        </div>
        <div class="modal__body">
          <img :src="picture" alt=""
               >
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ReviewModal",
  props: ['picture'],
  data: () => ({
    isVisible: false,
  }),
  methods: {
    open() {
      this.isVisible = true
    },
    close() {
      this.isVisible = false
    },
  },
};
</script>


<style>
.popup-modal {
  background-color: rgba(0, 0, 0, 0.73);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  align-items: center;
  z-index: 1100;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
/*.modal {*/
/*  overflow-x: hidden;*/
/*  overflow-y: auto;*/
/*  position: fixed;*/
/*  width: 100vw!important;*/
/*  height: 100vh!important;*/
/*  top: 0;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  z-index: 9;*/
/*  display: flex!important;*/
/*}*/
  .modal__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  .modal__dialog {
    background-color: #ffffff;
    position: relative;
    /*max-width: 900px;*/
    max-width: 600px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 2;
  }
/*    @media screen and (max-width: 992px) {*/
/*      width: 90%;*/
/*    }*/
/*  }*/
  .modal__close {
    width: 30px;
    height: 30px;
  }
  .modal__header {
    padding: 20px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .modal__body {
    padding: 10px 10px 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .modal__footer {
    padding: 10px 20px 20px;
  }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
