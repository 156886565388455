import { createApp } from 'vue/dist/vue.esm-bundler'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAxios from "vue-axios"
import axios ,{Axios} from "axios"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"
import "./assets/css/style.css"
import API from "@/api"


const app = createApp(App,{
    http: Axios,
})

app.config.globalProperties.API = new API()

app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.mount('#app')
