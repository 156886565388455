<template>
    <div class="container-fluid" v-if="data">
    <div class="container d-flex">
        <div class="b-contacts-menu d-none d-lg-block" style="margin-right: 24px;">
            <div class="b-contacts-menu__section">
                <div class="b-contacts-menu__section_wrap d-flex align-items-center" v-for="vc in data.vacancy_contact_data" :key="vc.id">
                    <img class="page_3_contacts-img-small" :src=vc.icon alt="">
                    <div class="b-contacts-menu__section_text-wrap">
                        <div class="side-bar-item">{{ vc.title }}</div>
                        <div class="b-contacts-menu__section_text-bold">{{ vc.body }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content__wrap p-md-4">
            <h1 class="header-1">{{ data.page_title }}</h1>
            <div class="page_3_content-1 list-style-auto" v-html="data.description_top">
            </div>
            <div class="content__images_wrap d-flex flex-wrap">
                <div class="content__images_card d-flex flex-column justify-content-between" v-for="v in data.vacancy" :key="v.id" @click="selectVacancy(v)">
<!--                  <img class="page_3_contacts-img-large" src="../assets/img/id_card.png" alt="">-->
                    <div>
                      <img class="page_3_contacts-img-large" :src=v.picture alt="">
                    <div class="content__images_card-text text-center">{{ v.position }}</div>
                    </div>
                  <div style="justify-content: flex-start; margin: 24px 12%" class="feedbackMoreDetailsFont" @click="selectVacancy(v.description)">
                    Подробнее
                    <img class="feedBackRedArrow" src="../assets/img/ArrowLeftLG.svg" alt="">
                  </div>
                </div>
            </div>
            <div class="page_3_content-2 rubik400 list-style-auto" v-html="data.description_bottom"></div>
            <div class="b-application-form">
                 <div class="b-application-form_wrap">
                    <h4 class="header-4 golos700">{{data.form_title}}</h4>
                    <div class="b-application-form_text rubik400">
                        {{data.form_description}}
                    </div>
                     <div class="b-application-form_input-wrap d-lg-flex">
                         <div class="b-application-form_input-block">
                             <div class="b-application-form_input-name rubik400">
                                 {{data.input_name}}
                             </div>
                             <div class="rubik400">
                             <input v-model="name" class="b-application-form_input-field" type="text" :placeholder=data.input_name_placeholder>
                             </div>
                         </div>
                         <div class="b-application-form_input-block">
                             <div class="b-application-form_input-name rubik400">
                                 {{data.input_phone}}
                             </div>
                             <div class="rubik400">
                             <input v-model="phone" class="b-application-form_input-field" type="text" :placeholder=data.input_phone_placeholder>
                             </div>
                         </div>
                     </div>
                      <div class="b-application-form_input-wrap d-lg-flex">
                         <div class="b-application-form_input-block">
                             <div class="b-application-form_input-name rubik400">
                                 {{data.input_education}}
                             </div>
                             <div class="rubik400">
                             <input v-model="education" class="b-application-form_input-field" type="text" :placeholder=data.input_education_placeholder>
                             </div>
                         </div>
                         <div class="b-application-form_input-block">
                             <div class="b-application-form_input-name rubik400">
                                 {{data.input_experience}}
                             </div>
                             <div class="rubik400">
                             <input v-model="experience" class="b-application-form_input-field" type="text" :placeholder=data.input_experience_placeholder>
                             </div>
                         </div>
                     </div>
                     <div class="b-application-form_input-name rubik400">{{data.input_comment}}</div>
                     <textarea v-model="content" class="b-application-form_input-field-comment" :placeholder=data.input_comment_placeholder name="comment" id="comment" cols="12" rows="10"></textarea>
                     <div class="d-lg-flex align-items-center" style="margin-top: 50px">
                         <a @click="submitVacancyPageForm()" type="submit" class="b-application-form_submit-wrap">Отправить
                             <img class="send-button" src="../assets/img/arrow.png" alt="" style="margin-left: 4px">
                         </a>
                         <div class="b-application-form_notice">
                             {{data.warning}}
                         </div>
                     </div>
                   <p v-if="submited" class="submited">Письмо успешно отправленно</p>
                 </div>
            </div>
        </div>
    </div>
</div>
  <teleport to="body">
    <VacancyModal ref="VacancyModal" :vacancy="selectedVacancy"/>
  </teleport>
</template>

<script>
import VacancyModal from "@/components/UI/VacancyModal";
export default {
  name: "VacancyPage",
  components: {VacancyModal},
  props: ['vacancy'],
  data:()=> {
    return {
      data: null,
      name: '',
      content: '',
      phone: '',
      education: '',
      experience: '',
      submited: false,
      selectedVacancy: null
    }
  },
  mounted() {
    this.loadData()
  },
  methods:{
    loadData(){
      const vm = this
      vm.API.getVacancyPage()
      .then(data=>vm.data=data)
    },
    submitVacancyPageForm(){
      const vm = this
      vm.axios.post(
            '/api/landing/vacancy_form/',
            {
              name: vm.name,
              phone: vm.phone,
              content: vm.content,
              education: vm.education,
              experience: vm.experience,
            })
      vm.submited = true,
      vm.name = '',
      vm.content= '',
      vm.education= '',
      vm.experience= '',
      vm.phone= ''
    },
    selectVacancy(vacancy){
      this.selectedVacancy = vacancy
      this.$refs.VacancyModal.open()
    },
  }
}
</script>

<style>
.submited {
  margin-top: 16px;
}
.b-contacts-menu__section {
    background: #FFFFFF;
    margin-top: 10px;
    /*width: 320px;*/
}
.b-contacts-menu__section:first-child {
    margin-top: 0;
}

.page_3_contacts-img-small {
    padding: 25px 35px;
}
.b-contacts-menu__section_text-wrap {
    padding-right: 74px;
    width: 280px;
    wrap: nowrap;
    font-family: 'Golos';
    font-weight: 600;
    font-size: 18px;
    line-height: 110%;
    color: #001D2C;
}

.b-contacts-menu__section_text-last-row_page-5 {
    display: block;
    align-self: center;
    padding-right: 80px;
}
.b-contacts-menu__section_text-last-row {
    margin-top: 31px;
    width: 180px;
}
.page_3_content-1 {
    margin-top: 20px;
  text-align: justify;
}
.page_3_header-2 {
    margin-top: 40px;
    margin-bottom: 100px;
  text-align: justify;
}

.content__images_wrap {
    /*width: 800px;*/
    /*justify-content: space-between;*/
    /*margin-left: 10px;*/

}
content__images_wrap:last-child {
    margin-left: 0;
}
.content__images_card {
    background-color: #FFFFFF;
    width: 256px;
    height: auto;
    margin-right: 10px;
    padding-bottom: 24px;
    margin-bottom: 10px;
  cursor: pointer;
}
.content__images_card:last-child {
    margin-right: 0px;
}
.page_3_contacts-img-large {
    margin: 24px auto;
    display: block;
    width: 200px;
  min-height: 200px;
    object-fit: cover;
}

.page_3_content-2 {
    margin-top: 80px;
}
/*PAGE_3*/


@media screen and (max-width: 768px) {
  .page_3_contacts-img-large {
    width: 50%;
  }
}
@media screen and (max-width: 576px) {
  .page_3_contacts-img-large {
    width: 100%;
}
}
</style>
