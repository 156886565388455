<template>
  <div class="container-fluid">
      <div class="container">
          <vue-preloader v-if="!data"/>
          <div v-else>
            <service-item
                v-for="service in data.services"
                :key="service.id"
                :service="service"
            />
          </div>
        <div class="clientsFeedbackHeader d-md-flex">
          <div class="clientsFeedbackFont">
            {{data.review_title}}
          </div>
          <div class="arrows">
              <img class="arrowImage" @click="$refs.carousel_settings.prev()" src="../assets/img/arrowLeft.png" alt="">
              <img class="arrowImage" @click="$refs.carousel_settings.next()" src="../assets/img/arrowRight.png" alt="">
          </div>
        </div>
        <carousel v-if="data.reviews" :settings="carousel_settings" :breakpoints="breakpoints" ref="carousel_settings">
          <slide v-for="review in data.reviews"
                 :key="review.id"

                 >
            <reviews-item :review="review"></reviews-item>
          </slide>
          <template #addons>
          </template>
        </carousel>
      </div>
  </div>
</template>

<script>

import VuePreloader from "@/components/UI/PreLoader";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import ServiceItem from "@/components/ServiceItem";
import ReviewsItem from "@/components/ReviewsItem";

export default {
  name: "ServicesPage",
  components: {ReviewsItem, ServiceItem, VuePreloader, Carousel, Slide},
  data(){
    return{
      data: {},
      carousel_settings: {
        itemsToShow: 1,
        snapAlign: 'start',
        next: false,
        prev: false
      },
      breakpoints: {
      768: {
        itemsToShow: 2,
        snapAlign: 'start',
      },
    },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData(){
      const vm = this
      vm.API.getServicePage()
      .then(data => {
        vm.data=data
        console.log("data", vm.data)
      })
    },

  }
}

</script>

<style>

</style>
