<template>
  <div class="container d-flex" v-if="section">
    <div class="b-equipment-menu d-none d-lg-block" style="margin-right: 24px;">
          <div v-if="data" class="position-fixed">
            <div class="side-bar"
              :class="{'bg-active': $route.query.section == section.id}"
              v-for="section in data.about_sections"
              :key="section.id"
               @click="loadSectionData(section.id)"
            ><div class="side-bar-item"
            >{{section.title}}</div></div>
          </div>
        </div>
    <div class="p-4 list-style-auto" v-html="section.content"></div>
  </div>
</template>

<script>


export default {
  name: "AboutPage",
    data() {
    return {
      data: null,
      section: null
    }
  },
  mounted() {
    this.loadData()
    this.loadSectionData()
  },
  methods:{
    loadData(){
      const vm = this
      vm.API.getAboutPage()
      .then(data=>vm.data=data)
    },
    loadSectionData(sectionId){
      if (sectionId) this.$router.push({name: 'AboutPage', query: {section: sectionId}})
      const vm = this
      vm.API.getAboutPageSection(sectionId)
      .then(data=>vm.section=data)
    },
  }
}
</script>

<style>
</style>
