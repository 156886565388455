<template>
  <div class="feedBackSection">
    <img class="feedbackImage" :src="review.icon" alt="">
      <div class="feedbackBodyFont">
      {{review.description}}
      </div>
      <div class="feedbackBottomRow d-md-flex">
        <div class="feedbackCEsignature">
          {{review.author}}
        </div>
        <div class="feedbackMoreDetailsFont" @click="selectPicture(review.picture)">
          Подробнее
          <img class="feedBackRedArrow" src="../assets/img/ArrowLeftLG.svg" alt="">
        </div>
      </div>
    </div>
  <teleport to="body">
    <ReviewModal ref="ReviewModal" :picture="selectedPicture"/>
  </teleport>

</template>

<script>
import ReviewModal from "@/components/UI/ReviewModal";
export default {
  name: "ReviewsItem",
  components: {ReviewModal},
  props: ['review'],
  data:()=> {
    return {
      selectedPicture: null
    }
  },
  methods: {
    selectPicture(picture){
      this.selectedPicture = picture
      console.log('picture', picture)
      this.$refs.ReviewModal.open()
    },
  }
}
</script>

<style>
/*.overflow-hidden {*/
/*  overflow: hidden;*/
/*}*/
</style>
