<template>
<div class="container-fluid">
    <div class="container d-flex" v-if="data">
        <div class="b-contacts-menu d-none d-lg-block" style="margin-right: 24px;">
            <div class="b-contacts-menu__section" v-for="item in data.contact_data" :key="item.id">
                <div class="b-contacts-menu__section_wrap d-flex align-items-center">
                    <img class="page_3_contacts-img-small" :src=item.icon alt="">
                    <div class="b-contacts-menu__section_text-wrap">
                        <div class="side-bar-item">{{ item.title }}</div>
                        <div class="b-contacts-menu__section_text-bold">{{ item.body }} </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content__wrap">
            <div class="b-chiefs-frame d-flex" style="margin-bottom: 40px">
                <div class="b-chiefs-wrap" v-for="contact in data.contacts" :key="contact.id">
                    <div class="b-chiefs-wrap__img">
                        <img class="" style="object-fit: cover;width: 100%;height: 100%;" :src=contact.picture alt="">
                    </div>
                    <div class="b-chiefs-wrap__text-1 side-bar-item">
                      {{ contact.position }}
                    </div>
                    <div class="b-chiefs-wrap__text-2">
                        {{ contact.name }}
                    </div>
                    <div class="b-chiefs-wrap__text-3 side-bar-item">
                        {{ contact.phone }}
                    </div>
                </div>
            </div>
            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A6c834ab5322fc14e2aaa4dcf4abd56fc36b51287f3356bf3bb8700b2bc1881c6&amp;source=constructor" width="800" height="422" frameborder="0"></iframe>
        </div>
    </div>
</div>
</template>

<script>

export default {
  name: "ContactsPage",
    data() {
    return {
      data: null
    }
  },
  mounted() {
    this.loadData()
  },
  methods:{
    loadData(){
      const vm = this
      vm.API.getContactsPage()
      .then(data=>vm.data=data)
    },
  }
}
</script>

<style>
/*PAGE_5*/
.b-contacts-menu__section_text-last-row_page-5 {
    display: block;
    align-self: center;
    padding-right: 80px;
}
.b-chiefs-frame {
    display: block;
    flex-wrap: wrap;
}
.b-chiefs-wrap {
    display: block;
    background: #FFFFFF;
    margin-right: 6px;
    margin-bottom: 6px;
    width: 320px;
}
.b-chiefs-wrap:last-child {
    margin-right: 0;
}

.b-chiefs-wrap__text-1 {
    line-height: 120%;
    font-size: 10px;
    text-transform: uppercase;
    color: #D62828;
    margin-top: 30px;
    margin-left: 30px;
}
.b-chiefs-wrap__text-2 {
    margin-top: 10px;
    margin-left: 30px;
    padding-right: 17%;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #001D2C;
}
.b-chiefs-wrap__text-3 {
    margin-top: 20px;
    margin-left: 30px;
    margin-bottom: 40px;
}
.page-5__Map {
    margin-top: 40px;
    margin-bottom: 100px;
}
.b-chiefs-wrap__img {
  width: 320px;height: 320px;
}

/*PAGE_5*/
@media screen and (max-width: 1199px) {
  iframe {
    width: 600px;
  }

}
@media screen and (max-width: 576px) {
  iframe {
    width: 92vw;
  }
  .b-chiefs-wrap__img {
    width: 92vw;
  }
  .b-chiefs-wrap {
    width: 92vw;
}
}
</style>
