<template>
<div class="container-fluid">
    <div class="container d-flex">
        <div class="b-equipment-menu d-none d-lg-block" style="margin-right: 24px;">
          <div v-if="data" class="position-fixed">
            <div class="side-bar"
              :class="{'bg-active': $route.query.section == section.id}"
              v-for="section in data.sections"
              :key="section.id"
               @click="$router.push({name: 'PartsPage', query: {section: section.id}})"
            ><div class="side-bar-item">{{section.title}}</div></div>
          </div>
        </div>
        <div class="content__wrap">
          <div v-if="section" class="section-container">
            <div v-for="block in section.content_blocks" :key="block.id" style="margin-bottom: 32px">
              <component :is="getBlockComponent(block)" :data="block"></component>
            </div>
          </div>
          <div class="b-application-form">
                 <div v-if="data" class="b-application-form_wrap">
                    <h4 class="header-4 golos700">{{$store.state.main.request_form.title}}</h4>
                    <div class="b-application-form_text rubik400">
                        {{$store.state.main.request_form.description}}
                    </div>
                     <div class="b-application-form_input-wrap d-lg-flex">
                         <div class="b-application-form_input-block">
                             <div class="b-application-form_input-name rubik400">
                                 {{$store.state.main.request_form.input_name}}
                             </div>
                             <div class="rubik400">
                             <input
                                 class="b-application-form_input-field"
                                 type="text"
                                 :placeholder=$store.state.main.request_form.input_name_placeholder
                                 v-model="name">
                             </div>
                         </div>
                         <div class="b-application-form_input-block">
                             <div class="b-application-form_input-name rubik400">
                                 {{$store.state.main.request_form.input_phone}}
                             </div>
                             <div class="rubik400">
                             <input
                                 class="b-application-form_input-field"
                                 type="text"
                                 :placeholder=$store.state.main.request_form.input_phone_placeholder
                                 v-model="phone">
                             </div>
                         </div>
                     </div>
                     <div class="b-application-form_input-name rubik400">{{$store.state.main.request_form.input_comment}}</div>
                     <textarea
                         class="b-application-form_input-field-comment"
                         name="comment"
                         id="comment"
                         cols="12"
                         :placeholder=$store.state.main.request_form.input_comment_placeholder
                         v-model="content"
                         rows="10"></textarea>
                     <div class="d-md-flex align-items-center" style="margin-top: 50px">
                         <a type="submit" class="b-application-form_submit-wrap" @click="submitPartsPageForm()">Отправить
                             <img class="send-button" src="../assets/img/arrow.png" alt="" style="margin-left: 4px">
                         </a>

                         <div class="b-application-form_notice">
                             {{$store.state.main.request_form.warning}}
                         </div>
                     </div>
                   <p v-if="submited" class="submited">Письмо успешно отправленно</p>
                 </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import RIchTextBlock from "@/components/blockContent/RIchTextBlock";
import TableBlock from "@/components/blockContent/TableBlock";

export default {
  name: 'PartsPage',
  data() {
    return {
      data: null,
      section: null,
      name: '',
      content: '',
      phone: '',
      submited: false,
    }
  },
  mounted() {
    this.loadData()
    this.loadSectionData(this.$route.query.section || 0)
  },
  watch:{
    '$route.query.section'(sectionId){
      this.loadSectionData(sectionId)
    }
  },
  methods:{
    loadData(){
      const vm = this
      vm.API.getPartsPage()
      .then(data=>vm.data=data)
    },
    loadSectionData(sectionId){
      const vm = this
      vm.API.getPartsPageSection(sectionId)
      .then(data=>vm.section=data)
    },
    getBlockComponent(block){
      switch (block.type){
        case 'rich': return RIchTextBlock
        case 'table': return TableBlock
      }
      return null
    },
    submitPartsPageForm(){
      const vm = this
      vm.axios.post(
            '/api/landing/parts_page_form/',
            {
              name: vm.name,
              phone: vm.phone,
              content: vm.content,
            })
      vm.submited = true,
      vm.name = '',
      vm.content= '',
      vm.phone= ''
    }
  }
};
</script>

<style>
.submited {
  margin-top: 16px;
}
</style>
