<template>
<div class="container-fluid" style="min-height: 140vh;">
    <div class="container d-flex">
        <div class="b-equipment-menu d-none d-lg-block" style="margin-right: 64px;">
          <div v-if="data" class="position-absolute" style="left: 80px;z-index: 1">
            <div class="side-bar"
              :class="{'bg-active': $route.query.section == section.id}"
              v-for="section in data.services"
              :key="section.id"
               @click="showSectionData(section.id)"
            ><div class="side-bar-item">{{section.title}}</div></div>
          </div>
        </div>
        <div class="content__wrap">
          <div v-if="section" class="section-container ps-md-4 list-style-auto" v-html="section.content">
          </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
  name: "ServicesPage",
  data() {
    return {
      data: null,
      section: null
    }
  },
  mounted() {
    this.loadData()
  },
  methods:{
    loadData(){
      const vm = this
      vm.API.getServicePage()
      .then(data=>{
        vm.data=data
        const currentSectionId = vm.$route.query.section || data.services[0].id
        vm.showSectionData(currentSectionId)
      })
    },
    showSectionData(sectionId) {
      if (sectionId) this.$router.push({name: 'services', query: {section: sectionId}})
      this.section = this.data.services.filter(service => service.id == sectionId)[0]
    }
  }
}
</script>

<style>
@media screen and (max-width: 1199px) {

h2 {
    margin-top: 0px!important;
}}
</style>
