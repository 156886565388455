<template>
<h2 class="">{{ data.title }}</h2>
  <table v-if="data.data.length" class="w-100">
    <thead>
    <tr class="table-header">
      <th v-for="(title, index) in data.data[0]" :key="index">{{title}}</th>
    </tr>
    </thead>
    <tbody>
      <tr v-for="(row, row_index) in data.data.slice(1)" :key="row_index">
        <td v-for="(cell, cell_index) in row" :key="cell_index">{{cell}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TableBlock",
  props: ['data']
}
</script>

<style scoped>

</style>
