import { createStore } from 'vuex'
import API from "@/api";

const api = new API

const store = createStore({
  state: {
    main: {},
    },
    getters:{
      MAIN(state){
        return state.main
      }
    },
    mutations: {
      SET_MAIN_ON_STATE(state, data){
        state.main = data
      }
    },
    actions: {
      async SET_MAIN_DATA({commit}){
        return new Promise((resolve, reject)=>{
          api.getMain()
            .then(data=> {
              commit('SET_MAIN_ON_STATE', data)
              resolve()
            })
            .catch(err=>reject(err))
        })
      }
    },
    modules: {
    }
})

export default store
