<template>
  <div class="container-fluid position-fixed d-block p-0" style="z-index: 101">
    <div class=" headerBackground " >
        <div class="HeaderLogo d-flex">
            <div @click="$router.push('/')" class="HeaderLogoWrap">
                <img class="logoImage" :src=MAIN.logo_icon alt="">
            </div>
                <img class="logoImageCurved " src="../assets/img/curvedLineHeader.png" alt="">

        </div>
                <img class="logoImageCurvedMobile" src="../assets/img/pikcha.png" alt="">
                <div class="mobileHeaderPatch">
                </div>
            <div class="navigation">
                 <div class="navigationWrap d-none d-xl-flex">
                     <router-link class="navBarFont"
                                   active-class="active"
                                  :to="{name: 'home'}">
                        {{ MAIN.pages.service_page.title }}
                      </router-link>
                     <router-link class="navBarFont" active-class="active" :to="{name: 'PartsPage'}">
                        {{ MAIN.pages.parts_page.title }}
                      </router-link>
                      <router-link class="navBarFont" active-class="active" :to="{name: 'ContactsPage'}">
                       {{ MAIN.page_titles.contacts_page }}
                       </router-link>
<!--                   <div class="navigationSubmenu" v-if="show" @mouseleave="show=false">-->
                       <router-link class="navBarFont" active-class="active" :to="{name: 'AboutPage'}">
                          {{ MAIN.page_titles.about_page }}
                        </router-link>
                       <router-link class="navBarFont" active-class="active" :to="{name: 'StockholdersPage'}">
                          {{MAIN.page_titles.stockholders_page }}
                        </router-link>
                       <router-link class="navBarFont" active-class="active" :to="{name: 'VacancyPage'}">
                          {{ MAIN.page_titles.vacancy_page }}
                        </router-link>

<!--                      <div class="drop" @click="show=true">-->
<!--                        <span class="navBarFont">о предприятии-->
<!--                          <img class="headerShevronDown" src="../assets/img/sDown.png" alt="">-->
<!--                        </span>-->
<!--                         <div class="navigationSubmenu" v-if="show" @mouseleave="show=false">-->
<!--                       <router-link class="dropdownItem" active-class="active" :to="{name: 'AboutPage'}">-->
<!--                          {{ MAIN.page_titles.about_page }}-->
<!--                        </router-link>-->
<!--                       <router-link class="dropdownItem" active-class="active" :to="{name: 'StockholdersPage'}">-->
<!--                          {{MAIN.page_titles.stockholders_page }}-->
<!--                        </router-link>-->
<!--                       <router-link class="dropdownItem" active-class="active" :to="{name: 'VacancyPage'}">-->
<!--                          {{ MAIN.page_titles.vacancy_page }}-->
<!--                        </router-link>-->
<!--                         </div>-->
<!--                      </div>-->

                 </div>
                    <div class="phone d-none d-xl-flex">
                         <div class="phoneWrap">
                             <img class="phoneIcon" src="../assets/img/phoneIcon.png" alt="">
                            <div class="phoneNumber ">
                                {{ $store.state.main.phone_number }}
                            </div>
                        </div>
                    </div>
            </div>
            <img @click="showMenu=!showMenu" class="mobile-menu" src="../assets/img/hamburger.svg" alt="">
    </div>
    <ul v-if="showMenu" class="b-mobile-menu mobile-dropdown-content">
            <li @click="routeTo({name: 'home'})" class="b-mobile-menu__item">
              <div class="rubik400">
                {{ MAIN.page_titles.service_page }}
              </div>
            </li>
            <li @click="clickPageSections('parts')" class="b-mobile-menu__item dropbtn">
              <span class="rubik400" :to="{name: 'PartsPage'}">
                {{ MAIN.pages.parts_page.title }}
              </span>
              <img class="b-mobile-menu__tick-down" id="tick" src="../assets/img/mobile-down.svg" alt="">
            </li>
            <li v-if="MAIN.pages && showPartsSections" class="dropdown-content">
              <div
                @click="routeTo({name: 'PartsPage', query: {section: section.id}})"
                class="rubik400 b-mobile-menu__item_submenu-item"
                v-for="section in MAIN.pages.parts_page.sections"
                :key="section.id"
              >{{section.title}}</div>
            </li>
            <li @click="routeTo({name: 'VacancyPage'})" class="b-mobile-menu__item">
              <div class="rubik400" >
                  {{ MAIN.page_titles.vacancy_page }}
              </div>
            </li>
            <li @click="clickPageSections('stockholders')" class="b-mobile-menu__item dropbtn-2">
              <span class="rubik400">
                {{MAIN.page_titles.stockholders_page }}
              </span>
            <img class="b-mobile-menu__tick-down" src="../assets/img/mobile-down.svg" alt="">
            </li>
              <li v-if="MAIN.pages && showStockholdersSections" class="dropdown-content">
              <div
                  class="rubik400 b-mobile-menu__item_submenu-item"
                  v-for="section in MAIN.pages.stockholders_page.sections"
                  :key="section.id"
                  @click="routeTo({name: 'StockholdersPage', query: {section: section.id}})"
              >{{section.title}}</div>
            </li>
            <li @click="routeTo({name: 'ContactsPage'})" class="b-mobile-menu__item">
              <div class="rubik400">
                 {{ MAIN.page_titles.contacts_page }}
              </div>
            </li>
      <li @click="clickPageSections('about')" class="b-mobile-menu__item dropbtn-3">
              <span class="rubik400">
                {{ MAIN.pages.about_page.title }}
              </span>
              <img class="b-mobile-menu__tick-down" src="../assets/img/mobile-down.svg" alt="">
            </li>
            <li v-if="MAIN.pages && showAboutSections" class="dropdown-content">
              <div
                @click="routeTo({name: 'AboutPage', query: {section: section.id}})"
                class="rubik400 b-mobile-menu__item_submenu-item"
                v-for="section in MAIN.pages.about_page.sections"
                :key="section.id"
              >{{section.title}}</div>
            </li>
    </ul>
</div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SiteNavBar",
  data() {
    return {
      data: null,
      section: null,
      showMenu: false,
      showPartsSections: false,
      showStockholdersSections: false,
      showAboutSections: false,
      show: false,
    }
  },
  computed:{
    ...mapGetters(['MAIN'])
  },
  mounted() {
  },
   methods: {
    clickPageSections(page){
      if (page==='parts'){
        this.showPartsSections = !this.showPartsSections
        this.showStockholdersSections = false
        this.showAboutSections = false
      } else if (page==='stockholders'){
        this.showPartsSections = false
        this.showAboutSections = false
        this.showStockholdersSections = !this.showStockholdersSections
      } else if (page==='about'){
        this.showPartsSections = false
        this.showStockholdersSections = false
        this.showAboutSections = !this.showAboutSections
      }
    },
     async routeTo(to){
       await this.$router.push(to)
       this.showMenu = false
       this.showPartsSections = false
       this.showStockholdersSections = false
       this.showAboutSections = false
     }
    },
}
</script>

<style>

.headerBackground {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*max-width: 1440px;*/
    background-color: #A0B3C1;
    margin-left: 0;
    margin-right: 0;
    height: 122px;
}
.active {
  border-bottom:1px solid #fff!important;
}
.headerNavBar{
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: space-around;
    background: #003049;
    padding-top: 35px;
    padding-bottom: 33px;
    margin-left: -25px;
    /*height: 80px;*/
    max-height: inherit;
}
.HeaderLogo {
    width:   385px;
    height: 122px;
    display: flex;
    justify-content: space-between;
    /*background-color: #D62828;*/


    z-index: 1;
}
.HeaderLogoWrap {
    display: flex;
    align-items: center;
    height: inherit;
    padding-left: 51px;
    background-color: #D62828;
    padding-top: 25px;
    padding-bottom: 25px;
    /*position: relative;*/
    /*margin-right: -9px;*/
    z-index: 2;
    cursor: pointer;
}
.logoImage {
    padding-right: 10px;
    max-height: 72px;
}
.logoImage_right {
    padding-right: 37px;
}
.logoImageCurved{
    justify-self: flex-end;
    color: #D62828;
    margin-left: -2px;

}
.logoImageCurvedMobile {
    /*position: relative;*/
    display: none;
    /*min-height: 76px;*/
    height: 144px;
    margin-left: -4px;
    /*right: 0;*/
    /*margin-right: -28px;*/
    /*height: 76px;*/
    z-index: 1;
}
.navigation a {
    text-decoration: none;
}
.headerShevronDown {
    padding-left: 9px;
  padding-bottom: 3px;
}

.navigationSubmenu {
    /*display: none;*/
    position: absolute;
    margin-top: 15px;
    background-color: #034669;
    /*display: block;*/
    z-index: 10;
}

.drop{
    margin-right: 15px;
    background-color: transparent;

}
/*.navBarFont.visited {*/
/*  color: #e93a37;*/
/*}*/
/*.drop:hover>.navigationSubmenu{*/
/*    display: block;*/
/*}*/

.dropdownItem {
    display: block;
    padding-left: 30px;
    padding-top: 24px;
    padding-right: 46px;
    padding-bottom: 24px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    text-transform: uppercase;
    color: #FFFFFF;
    border-bottom: 1px #A0B3C1;
  cursor: pointer;
}
.dropdownItem:hover {
    color: #FFFFFF;
  cursor: pointer;
}
.headerDropdownMenu {
 background-color: #034669;
}
.headerDropdownMenuFont {
    font-family: Rubik;
    font-weight: normal;
    font-height: 12px;
    font-height: 100%;
    color: #FFFFFF;
}

.navigation{
    display: flex;
    align-self: flex-start;
    background: #003049;
    width: 100%;
    margin-left: -50px;
    justify-content: space-between;
}
.navigationWrap {
    /*display: flex;*/
    justify-content: space-around;
    align-items: center;
    padding-top: 35px;
    padding-bottom: 30px;
    margin-left: 50px;
    width: 100%
}
.navBarFont {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    text-transform: uppercase;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
  padding-bottom: 4px;
}
.navBarFont:hover {
    color: #FFFFFF;
}
.phone {
    background-color: #034669;
    display: flex;
    padding-top: 31px;
    padding-bottom: 31px;
    justify-content: center;
    align-items: center;
    width: 40%;
}
.phoneWrap {
    display: flex;
    align-items: center;
}

.phoneIcon{
    padding-right: 19px;
}
.phoneNumber {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #FFFFFF;
}


.rubik400 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: #001D2C;
    text-decoration: none;
}
.dropdownItem {
    display: block;
    padding-left: 30px;
    padding-top: 24px;
    padding-right: 46px;
    padding-bottom: 24px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    text-transform: uppercase;
    color: #FFFFFF;
    border-bottom: 1px #A0B3C1;
}
.mobileHeader {
    display: flex;
    position: absolute;
    display: block;
    /*align-items: center;*/
    height: 121px;
    width: 100%;
    z-index: 0;
}
.mobileHeaderContent {
    position: relative;
    display: flex;
    padding-top: 14px;
    padding-left: 20px;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
}

.mobile-menu {
    display: none;
    justify-content: flex-end;
    align-self: center;
    margin-right: 23px;
    width: 52px;
    height: 52px;
    /*Размер в мобильной версии 46px*/
    /*background-color: red;*/
}
.mobile-menu:hover {background-color: transparent}
.mobile-dropdown-content {
  /*display: none;*/
  /*position: absolute;*/
  /*background-color: #f1f1f1;*/
  /*min-width: 100%;*/
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.b-mobile-menu {
    /*display: none;*/
    justify-content: flex-end;
    /*flex-direction: column;*/
    /*width:100%;*/
    padding-left: 0;
    margin-left: 30%;
    background-color: #FFFFFF;
}
.showMobile {display:block;}
.none {display:none;}
.b-mobile-menu__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin-bottom: 1px;
    padding-top: 22px;
    padding-bottom: 21px;
    padding-left: 20px;
    padding-right: 25px;
    /*background: rgba(160, 179, 193, 0.15);*/
    background: #a0b3c126;
}
.b-mobile-menu__item:hover {
  background-color: #2980B9;
}
.b-mobile-menu__tick-down {
    /*padding: 0;*/
    /*margin: 0;*/
    color: #001D2C;
}
.b-mobile-menu__item_submenu{
    display: block;
    width: inherit;
    height: inherit;
}
.b-mobile-menu__item_submenu-item:hover {
    background-color: #2980B9;
}
.b-mobile-menu__item_submenu-item {
    background: #FFFFFF;
    padding-left: 40px;
    display: block;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    margin-bottom: 1px;
    padding-top: 22px;
    padding-bottom: 21px;
}
.tick {
    display: block;
}

.b-mobile-menu__item_submenu:hover>.b-mobile-menu__item_submenu-item {
    display: none;
}
.b-mobile-menu__item_submenu:hover>.li.b-mobile-menu__item_submenu-item {
    display: none;
}


.drop {
  height: 28px;
}
/*MOBILE_DROPDOWN */
/* Кнопка выпадающего списка */
.dropbtn {
  /*background-color: transparent;*/
  /*color: white;*/
  /*padding: 16px;*/
  /*font-size: 16px;*/
  border: none;
  cursor: pointer;
}

/* Кнопка выпадающего меню при наведении и фокусировке */
.dropbtn:hover,.dropbtn-2:hover,.dropbtn-3:hover, .dropbtn:focus {
  background-color: #2980B9;
}

/* Контейнер <div> - необходим для размещения выпадающего содержимого */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Выпадающее содержимое (скрыто по умолчанию) */
.dropdown-content {
  /*display: none;*/
  /*position: absolute;*/
  background-color: #f1f1f1;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
/* Изменение цвета выпадающих ссылок при наведении курсора */
.dropdown-content a:hover {background-color: #ddd}

/* Показать выпадающее меню (используйте JS, чтобы добавить этот класс .dropdown-content содержимого, когда пользователь нажимает на кнопку выпадающего списка) */
.show {display:block;}

.flip {transform: rotate(180deg);}
/*MOBILE_DROPDOWN*/
.clientsFeedbackActive {
    display: none;
    justify-content: center;
    margin-top: 30px;

}


@media screen and (max-width: 1199px) {
    .container {
        margin-left: 0;
        margin-right: 0;
    }
    .headerBackground {
    background-color: #003049;
    /*justify-content: unset;*/
    /*align-items: center;*/
        height: 100px;
}
    .HeaderLogo {
        height: 100px;
        width: 100%;
        display: flex;
        justify-content: space-between;

}
    .HeaderLogoWrap {
    display: flex;
        width: 100%;

    padding-left: 20px;
    /*background-color: #D62828;*/
    padding-top: 20px;
    padding-bottom: 20px;
        padding-right: 7px;
    /*position: relative;*/
        margin-right: -5px;
}
    .mobile-menu {
        display: block;
    }
    .logoImage {
    padding-right: 10px;
}
    .logoImageCurved{
        display: block;
    justify-self: flex-end;
    color: #D62828;
    margin-right: -33px;
}

    HeaderLogoWrap {
        /*width: 100%;*/
        background-color: #D62828;

    }
    /*.navigation {*/
    /*    display: flex;*/
    /*}*/
    b-mobile-menu__icon {
        display: block;
        /*justify-self: flex-end;*/
        /*align-self: center;*/
        /*margin-left: 7px;*/
        /*z-index: 13;*/
        /*width:250px;*/
        /*height: 150px;*/
        background-color: red;
    }
    .bodySection{
        display: flex;
        flex-direction: column;
        /*align-items: center;*/
    }
    .bodyImage {
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        /*justify-self: center;*/
        padding: 0;
        /*padding-top: 20px;*/
        /*padding-left: 20px;*/
        /*padding-right: 20px;*/
        /*max-width: 320px;*/
        /*min-height: 267px;*/
    }
    .bodyLeftColumn {
        display: flex;
        justify-content: flex-start;
    }

    .bodyRightColumn {
        display: flex;
        justify-content: center;
        margin-top: 24px;
        margin-bottom: 24px;
        padding-left: 20px;
        padding-right: 0;
    }
    .clientsFeedbackHeader{
        display: flex;
        justify-content: center;
    }
    .feedBackSection_2 {
        display: none;
    }
    .clientsFeedbackActive {
        display: flex;
    }
    .mobile_footer {
        margin-top: 80px;
    }
}

@media screen  and (max-device-width: 576px) {
.bodyLeftColumn {
        max-width: 90%;
    }
    .headerBackground {
        /*max-width: 576px ;*/
    background-color: #003049;
    /*justify-content: unset;*/
    /*align-items: center;*/
        height: 60px;
}
    .HeaderLogo {
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: space-between;

}
    .HeaderLogoWrap {
    display: flex;
    align-items: center;
    height: inherit;
    padding-left: 20px;
    background-color: #D62828;
    padding-top: 14px;
    padding-bottom: 14px;
    /*position: relative;*/
    /*margin-right: -9px;*/
    z-index: 2;
}
    .logoImage {
        max-height: 40px;
    padding-right: 10px;
}
    .logoImage_right {
    max-height: 40px;
    width: 134px;
}
    .mobileHeaderPatch {
        /*position: relative;*/
        display: flex;
        justify-self: flex-end;
        align-self: flex-start;
        background-color: #D62828;
        height: 40px;
        width: 10px;
        margin-left: -30px;
        z-index: 1;
    }
 .logoImageCurved {
        display: none;
    }
 .logoImageCurvedMobile {
        /*position: absolute;*/
     display: block;
     height: 76px;
     margin-left: -3px;
     z-index: 0;
 }
 .mobile-menu {
     width: 46px;
     height: 46px;

 }
.bodyLeftColumn{
    max-width: 90% !important;
  }

    .bodySection:first-child{
        margin-top: 80px;

    }
}
@media screen and (max-device-width: 340px) {
    .container-fluid {
        /*width: 360px;*/
    }
    .container {
        /*width: 320px;*/
    }
        .headerBackground {
        /*max-width: 360px ;*/
    background-color: #003049;
    /*justify-content: unset;*/
    /*align-items: center;*/
        height: 60px;
}
    .HeaderLogo {
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: space-between;

}
    .HeaderLogoWrap {
    display: flex;
    align-items: center;
    height: inherit;
    padding-left: 20px;
    background-color: #D62828;
    padding-top: 14px;
    padding-bottom: 14px;
    /*position: relative;*/
    /*margin-right: -9px;*/
    z-index: 2;
}
    .logoImage {
        max-height: 32px;
    padding-right: 10px;
}
    .logoImage_right{
        max-height: 32px;
        width: 93px;
    }
    .mobileHeaderPatch {
        /*position: relative;*/
        display: flex;
        justify-self: flex-end;
        align-self: flex-start;
        background-color: #D62828;
        height: 40px;
        width: 10px;
        margin-left: -30px;
        z-index: 1;
    }
 .logoImageCurved {
        display: none;
    }
 .logoImageCurvedMobile {
        /*position: absolute;*/
     display: block;
     height: 76px;
     margin-left: -3px;
     z-index: 0;
 }
 .mobile-menu {
     width: 46px;
     height: 46px;

 }
    .b-mobile-menu {
    display: block;
    /*justify-content: flex-end;*/
    /* flex-direction: column; */
     width: 100%;
    padding-left: 0;
    margin-left: 0  ;
    background-color: #FFFFFF;
}
    .dropdown-content {
        width: 100%;
    }

    .bodySection:first-child{
        margin-top: 80px;

    }
}

</style>
