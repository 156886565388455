<template>
<div class="list-style-auto" v-html="data.content"></div>
</template>

<script>
export default {
  name: "RIchTextBlock",
  props: ['data']
}
</script>

<style>

</style>
