<template>
  <div class="bodySection d-block d-lg-flex">
     <router-link class="bodyLeftColumn" :to="{name: 'services', query: {section: service.id}}">
         <img class="bodyImage" :src="service.picture" alt="" style="width:100%;max-width: 480px">
     </router-link>
     <div class="bodyRightColumn">
         <router-link class="bodyHeaderFont" :to="{name: 'services', query: {section: service.id}}">{{ service.title }}</router-link>
         <div class="bodyStringWrap" v-for="d in service.descriptions" :key="d.id">
         <img class="tickInBox" src="../assets/img/tickInBox.png" alt="">
             <div class="bodyText">
               {{ d.text }}
             </div>
         </div>
       <router-link class="feedbackMoreDetailsFont doAction"
            style="margin-top: 16px;text-decoration: none;"
            :to="{name: 'services', query: {section: service.id}}"
            >
          Подробнее
          <img class="feedBackRedArrow btnIcon" src="../assets/img/ArrowLeftLG.svg" alt="">
        </router-link>
     </div>
 </div>
</template>

<script>
export default {
  name: "ServiceItem",
  props: ['service', 'descriptions']
}
</script>

<style>
.bodySection{
    background-color: #FFFFFF;
    margin-top: 20px;
    display: flex;
}
.bodySection:hover {
  background-color: #cecece64;
}
.bodyLeftColumn {
    justify-content: flex-start;
}
.bodyImage{
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
}
.bodyRightColumn{
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
    align-self: center;
    width: -webkit-fill-available;
}
.bodyHeaderFont {
    font-family: 'Golos';
    font-weight: bold;
    font-size: 24px;
    line-height: 110%;
    color:#001D2C;
  text-decoration: none;
}
.bodyHeaderFont:hover {
  color:#001D2C;
}
/*.bodyCommonWrap {*/
/*    flex-direction: column;*/
/*    justify-content: space-evenly;*/
/*    align-items: center;*/
/*}*/
.bodyStringWrap{
    margin-top: 16px;
    display: flex;
}
.bodyStringWrap:first-child {
    margin-top: 24px;
}
.tickInBox {
    width: 16px;
    height: 16px;
    margin-top: 2px;
}
.bodyText {
    padding-left: 20px;
    font-weight: 400;
    font-family: 'Rubik';
    font-style: normal;
    font-size: 14px;
    line-height: 160%;
    color: #001D2C;
  text-align: justify;
}

@media screen  and (max-device-width: 576px) {
  .bodyLeftColumn{
    max-width: 90%;
  }
}
</style>
