<template>
  <div class="container d-flex" v-if="section && data">
    <div class="b-equipment-menu d-none d-lg-block" style="margin-right: 24px;">
          <div class="position-fixed">
            <div class="side-bar"
              :class="{'bg-active': $route.query.section == section.id}"
              v-for="section in data.stockholders_sections"
              :key="section.id"
            ><div
                class="side-bar-item"
                @click="loadSectionData(section.id)"
            >{{section.title}}</div></div>
          </div>
        </div>
    <div v-html="section.content" class="list-style-auto" style="padding-left: 24px;padding-right: 24px"></div>
  </div>
</template>

<script>

export default {
  name: "StockholdersPage",
  data() {
    return {
      data: null,
      section: null
    }
  },
  mounted() {
    this.loadData()
    this.loadSectionData(this.$route.query.section || 0)
  },
  watch:{
    '$route.query.section'(sectionId){
      this.loadSectionData(sectionId)
    }
  },
  methods:{
    loadData(){
      const vm = this
      vm.API.getStockholdersPage()
      .then(data=>vm.data=data)
    },
    loadSectionData(sectionId){
      if (sectionId) this.$router.push({name: 'StockholdersPage', query: {section: sectionId}})
      const vm = this
      vm.API.getStockholdersPageSection(sectionId)
      .then(data=>vm.section=data)
    },
  }
}
</script>

<style>
figure img {
      max-width: 800px;
    object-fit: contain;
}
@media screen and (max-width: 1299px) {
  figure img {
      /*max-width: 520px!important;*/
    object-fit: contain;
}

}
</style>
