<template>
  <div class="container-fluid footerBase position-absolute bottom-0 p-0">
      <div class="footerJustifier1 m-0 d-block d-lg-flex">
          <div class="wrapper position-relative">
             <div class="footerNavBar d-none d-xl-flex">
                 <div><router-link class="footerNavBarFont" :to="{name: 'home'}">
                {{ MAIN.page_titles.service_page }}
              </router-link></div>
                 <div><router-link class="footerNavBarFont" :to="{name: 'PartsPage'}">
                {{ MAIN.page_titles.parts_page }}
              </router-link></div>
                 <div><router-link class="footerNavBarFont" :to="{name: 'ContactsPage'}">
                {{ MAIN.page_titles.contacts_page }}
              </router-link></div>
                 <div><router-link class="footerNavBarFont" :to="{name: 'AboutPage'}">
                {{ MAIN.page_titles.about_page }}
              </router-link></div>
               <div><router-link class="footerNavBarFont" :to="{name: 'StockholdersPage'}">
                {{MAIN.page_titles.stockholders_page }}
              </router-link></div>
               <div><router-link class="footerNavBarFont" :to="{name: 'VacancyPage'}">
                {{ MAIN.page_titles.vacancy_page }}
              </router-link></div>
             </div>
              <div class="footerLeftColumn footerBottomRowFont d-none d-md-block" style="min-height: 74px;">
                  {{ $store.state.main.copyright }}
              </div>
         </div>
         <div class="footerRightColumn footerBottomRowFont d-none d-md-block" style="min-height: 74px;">
             {{ $store.state.main.address }}
         </div>
      </div>
    <div class="mobile_version_footer d-md-none">
      <div class="footerRightColumn footerBottomRowFont" style="min-height: 74px;padding-right: 0;">
       {{ $store.state.main.address }}
   </div>
    <div class="footerLeftColumn footerBottomRowFont" style="min-height: 74px;">
        {{ $store.state.main.copyright }}
    </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SiteFooter",
  computed:{
    ...mapGetters(['MAIN'])
  }
}
</script>

<style scoped>
.footerBase {
    padding-top: 100px;
}
.footerVerticalAlign {
    flex-direction: column;
}
.footerJustifier1 {
    display: flex;
    justify-content: space-between;
}

flexDirectionColumn {
    flex-direction: column;
}
.wrapper {
    width: 100%;
}
.wrapper:after {
     content: "";
    position: absolute;
    bottom: 0;
    right: -47px;
    z-index: 101;
    height: 100%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAABmCAYAAAC5rNNxAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAO4SURBVHgBzZq7ctNAFIbPrmygSGEzNFSQLh15A8QTwBsATxDegOdhqIkZOmJqTEXjzEDhwQyZwcwQIL5IQseXjCWdlXZXe/s6bVx8o3/3nLOO2fTiZwZbrjqsf9jv/4KA4PsPt65WxxAYBcGM8bAFgbEYAqMgyDk8hMAoRgzQ+zb7dw8CgpcX0uWfRxAQvLrEgjooFcEOY0Htw4pgvg+Pv8xmPQgETi2GVLBJwZTzGAKBFOQ8nH1ICrJ8H0IgkIJYsKfTixgCgIv+EMrgIBQMZXAQCoYyOIgjzvfhZDLzHjOv/WMnjcEztYIh7MP6NxjAPqwVDKEe8qYP+K6HjYIsYo/BI82CnufD5ojzfehzPmwURJKIPwFPSAlGjD0AT0gJ5sS+9qGsIHSXiZeYpQUjiLx0FWlBxrKw36CvtictiPi4jioJ+riOKgmCh3KjKui83CgLui43yoKuy42yoOtyoyyIuJxu2Oejo6zxQwcH0Bu821uAr3fv3D4EB0i9wezyElaj0d4C3Hd1qZeOeDn6WHhm3dRJzNKCq09FQVddRVowGY/zqH/vLznpKvLTTL4Pk/F5Ye3mPH0GllEqM4vh+8KzizuzkmDhJG+wHrOSYHJe2YfWY1buJIuzYeHZdszKguVyA5ZjVhZcDs8qazZjVp9m1uVmXFizGbPWNFNue2AxZj3BD+5i1psHq23PWsxaglTbA0sxawki5baH2IhZW3A5HFbWbMSsLZh+n0I6nZaXY9O/u9EWRBZE0c4Wf5+DQVoJUuWGR+wpGKSVIFVu8EJl8t7cSrBy29ti8t7cShAh2h7+eslYzK0FF4PTyprJr0daC4pihk70EgzQWhChYjb1Pz4jgsSUvY7ZROszI5hHTHQVI63PiCBCdZWcuO1hMSZIdRWkbU00JogxV7oKbGpim8NiTBAp35kRPCw3kiQGTcwKvn1DrvOMn4AmRgXJ4WFDPJn80PpG1qig4K6yhnUjrcNiVBCZD+iYI85OdA6LccElXQ+1D4txQeHwAHqHxbggQl1Jtyh3FjuCxIy4Q7WzWBGsi1m1s1gRREQxrw/LIn0BktgTrIlZ5WpqTbAuZpWrqTVBpOY0S99Z7ArmMQt6MyJVcqwK1vVmRKbkWBVERL0ZkSk51gWxN4tilik51gUxZmrS3tE05VgXRESTNtJ0f3YiKLpQ7WAdJpxynAgi81NxZ6kr3M4ERffmawSF25kgxixsfRvIwu1MEKG+BStAvEWngvPXr5o+UnmLTgVrJ5wdpbf4H8p8sIM/kS08AAAAAElFTkSuQmCC);
    width: 49px;
    background-repeat: no-repeat;
    background-size: cover;
}

.footerBottomRow {
    justify-content: stretch;
}
.footerNavBar {
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 73px;
    padding-top: 14px;
    padding-bottom: 14px;
    background: rgba(160, 179, 193, 0.2);
    display: flex;
}
.footerNavBar > div{
    margin: 0 5px;
    white-space: nowrap;
}
.footerNavBar a {
    text-decoration: none;
}
.footerNavBarFont {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #001D2C;
    z-index: 1;
  cursor: pointer;
}
.footerNavBarFont:hover {
    color: #001D2C;
    cursor: pointer;
}
.footerBottomRowFont {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #FFFFFF;
}
.footerLeftColumn {
    justify-content: center;
    align-items: center;
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 80px;
    padding-right: 140px;
    background: #D62828;
}
.footerRightColumn {
    position: relative;
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 90px;
    padding-right: 0px;
    background: #003049;
    align-self: flex-end;
    width: 100%;
}
</style>
