<template>
  <div v-if="loading" style="height: 100vh; width: 100vw" class="position-relative">
    <pre-loader/>
  </div>
  <div v-else>
    <SiteNavBar/>
      <router-view/>
    <SiteFooter/>
  </div>
</template>

<script>

import SiteNavBar from "@/components/SiteNavBar";
import SiteFooter from "@/components/SiteFooter";
import PreLoader from "@/components/UI/PreLoader";
export default {
  name: "SiteBase",
  components: {PreLoader, SiteFooter, SiteNavBar},
  data:()=>{
    return{
      loading: true
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData(){
      const vm = this
      vm.$store.dispatch('SET_MAIN_DATA')
        .then(()=>vm.loading = false)
        .catch((err)=>console.error(err))
    },
  }

}
</script>

<style>

</style>
