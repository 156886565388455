<template>
  <div class="container">

        <div class="spinner-border" role="status" style=" width: 320px;
                                                          height: 320px;
                                                          margin: auto;
                                                          display: block;">
          <span class="visually-hidden">Loading...</span>
        </div>

  </div>
</template>

<script>
export default {
  name: "PreLoader"
}
</script>

<style scoped>

</style>
